import { config } from "../config";
import { Club } from "../types/club";

export const getClub = async (clubId: string): Promise<Club> => {
  const cachedProblem = localStorage.getItem(`linkup-club-${clubId}`);
  if (cachedProblem !== null && cachedProblem !== "undefined") {
    return Promise.resolve(JSON.parse(cachedProblem));
  }
  return fetch(`${config.baseUrl}/clubs/${clubId}`)
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      } else {
        console.error(`${resp.status} response from club api`);
      }
    })
    .then((club) => {
      localStorage.setItem(`linkup-club-${clubId}`, JSON.stringify(club));
      return club;
    })
    .catch((err) => console.error(err));
};
