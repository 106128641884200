import React, { ReactNode } from "react";
import TickIcon from "@mui/icons-material/CheckCircle";
import CrossIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/RemoveCircle";
import StarsIcon from "@mui/icons-material/Stars";
import { Player } from "./types/player";
import { Side } from "./Graph";
import { PlayerAndSquad } from "./types/playerAndSquad";

export enum GuessOutcome {
  HIT,
  MISS,
  PENDING,
  WIN,
}

export type Guess = {
  outcome: GuessOutcome;
  player: Player;
  connections: PlayerAndSquad[] | string[];
  side: Side;
};

const BadgeWrapper = ({ children }: { children: ReactNode }) => (
  <div className="bg-white rounded-full text-xl sm:text-2xl md:text-3xl lg:text-4xl flex drop-shadow-lg">
    {children}
  </div>
);

const GuessBadge = ({ guess }: { guess: Guess }) => {
  switch (guess.outcome) {
    case GuessOutcome.WIN:
      return (
        <BadgeWrapper>
          <StarsIcon className="text-green-500" fontSize="inherit" />
        </BadgeWrapper>
      );
    case GuessOutcome.HIT:
      return (
        <BadgeWrapper>
          <TickIcon className="text-green-500" fontSize="inherit" />
        </BadgeWrapper>
      );
    case GuessOutcome.MISS:
      return (
        <BadgeWrapper>
          <CrossIcon className="text-red-500" fontSize="inherit" />
        </BadgeWrapper>
      );
    case GuessOutcome.PENDING:
      return (
        <BadgeWrapper>
          <PendingIcon className="text-slate-500" fontSize="inherit" />
        </BadgeWrapper>
      );
  }
};
const GuessesLeft = ({ count }: { count: number }) => (
  <div className="bg-amber-300 flex font-bold gap-1 items-center mr-1 pr-1 collapse sm:visible drop-shadow-lg">
    <div className="bg-black text-amber-300 w-8 h-full flex items-center justify-center">
      {count}
    </div>
    GUESSES
  </div>
);

export const GuessDisplay = ({
  guesses,
  disableMiddle = false,
}: {
  guesses: Guess[];
  disableMiddle?: boolean;
}) => {
  const paddedGuesses = [
    ...guesses,
    ...Array(10).fill({ outcome: GuessOutcome.PENDING, player: null }),
  ];

  return (
    <div className="flex gap-1 justify-between w-full px-2 sm:px-10">
      <div className="flex gap-1">
        {paddedGuesses.slice(0, 5).map((g, i) => (
          <GuessBadge key={`badge-l-${i}`} guess={g} />
        ))}
      </div>
      {!disableMiddle && <GuessesLeft count={10 - guesses.length} />}
      <div className="flex gap-1">
        {paddedGuesses.slice(5, 10).map((g, i) => (
          <GuessBadge key={`badge-r-${i}`} guess={g} />
        ))}
      </div>
    </div>
  );
};
