import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";

import "reactflow/dist/style.css";
import { Graph, Side } from "./Graph";
import { SearchBar } from "./Searchbar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Edge,
  Node,
} from "reactflow";
import { Player } from "./types/player";
import { findPlayerConnections, getPlayer } from "./api/players";
import { formatDate, getProblem } from "./api/problems";
import { Guess, GuessDisplay, GuessOutcome } from "./GuessDisplay";
import { EndGameModal } from "./EndGameModal";
import { Problem } from "./types/problem";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { HelpModal } from "./HelpModal";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { PlayerAndSquad } from "./types/playerAndSquad";

dayjs.extend(utc);
dayjs.extend(timezone);

const getNodeDepth = (guess: Guess, players: Guess[]) => {
  if (guess.connections.length === 0) {
    return 0;
  }
  let guesses = [guess];
  let done = false;
  let depth = 0;

  while (!done) {
    depth++;
    const nextGuesses: Guess[] = [];
    guesses
      .flatMap((g) =>
        g.connections.map((c) =>
          typeof c === "string"
            ? (c as string)
            : (c as PlayerAndSquad).player_id
        )
      )
      .forEach((c) => {
        const nextGuess = players.find((p) => p.player.id === c);
        if (nextGuess === undefined) {
          done = true;
        } else {
          nextGuesses.push(nextGuess);
        }
      });
    guesses = nextGuesses;
  }
  return depth;
};

function appendState<T>(
  state: T[],
  setState: Dispatch<SetStateAction<T[]>>,
  obj: T
): void {
  setState([...state, obj]);
}

let leftPlayerIDs: string[] = [];
let rightPlayerIDs: string[] = [];

const buildGraph = (
  problem: Problem,
  guesses: Guess[],
  setNodes: Dispatch<SetStateAction<Node[]>>,
  setEdges: Dispatch<SetStateAction<Edge[]>>,
  portrait: boolean
) => {
  if (problem === null) {
    return;
  }

  leftPlayerIDs = [problem.left_player_id];
  rightPlayerIDs = [problem.right_player_id];

  const lDepths: Map<string, number> = new Map();
  const lCounts: Map<number, number> = new Map();
  const lDone: Map<number, number> = new Map();

  const rDepths: Map<string, number> = new Map();
  const rCounts: Map<number, number> = new Map();
  const rDone: Map<number, number> = new Map();

  guesses.forEach((g: Guess) => {
    if (g.outcome === GuessOutcome.MISS) {
      return;
    }

    const depth = getNodeDepth(g, guesses);

    if (g.side === Side.LEFT) {
      lDepths.set(g.player.id, depth);
      lCounts.set(depth, (lCounts.get(depth) ?? 0) + 1);
    } else {
      rDepths.set(g.player.id, depth);
      rCounts.set(depth, (rCounts.get(depth) ?? 0) + 1);
    }
  });

  const width = lCounts.size + rCounts.size + 1;
  const xSpace = Math.max(200, 1000 / width);
  const ySpace = portrait ? 200 : 150;

  const nodes: Node[] = [
    {
      id: problem.left_player_id,
      type: "player",
      position: { x: 0, y: 0 },
      data: { player: problem.left_player, side: Side.LEFT },
      draggable: false,
    },
    {
      id: problem.right_player_id,
      type: "player",
      position: portrait
        ? { y: xSpace * width, x: 0 }
        : { x: xSpace * width, y: 0 },
      data: { player: problem.right_player, side: Side.RIGHT },
      draggable: false,
    },
  ];
  const edges: Edge[] = [];

  guesses.forEach((g: Guess) => {
    if (g.outcome === GuessOutcome.MISS) {
      return;
    }

    let nodeColumn, columnLength, nodeRow;
    if (g.side === Side.LEFT) {
      nodeColumn = lDepths.get(g.player.id) ?? 0;
      columnLength = lCounts.get(nodeColumn) ?? 0;
      nodeRow = lDone.get(nodeColumn) ?? 0;
      leftPlayerIDs.push(g.player.id);
    } else {
      nodeColumn = rDepths.get(g.player.id) ?? 0;
      columnLength = rCounts.get(nodeColumn) ?? 0;
      nodeRow = rDone.get(nodeColumn) ?? 0;
      rightPlayerIDs.push(g.player.id);
    }

    let playerNode = {
      id: g.player.id,
      type: "player",
      position: {
        x:
          g.side === Side.LEFT
            ? xSpace * nodeColumn
            : (width - nodeColumn) * xSpace,
        y: 2 * ySpace * nodeRow + (1 - columnLength) * ySpace,
      },
      data: {
        player: g.player,
        side: g.side,
        animate:
          guesses.length > 0 &&
          g.player.id === guesses[guesses.length - 1].player.id,
      },
      draggable: false,
    };

    if (portrait) {
      playerNode = {
        ...playerNode,
        position: {
          x: playerNode.position.y,
          y: playerNode.position.x,
        },
      };
    }

    nodes.push(playerNode);

    if (g.side === Side.LEFT) {
      lDone.set(nodeColumn, (lDone.get(nodeColumn) ?? 0) + 1);
    } else {
      rDone.set(nodeColumn, (rDone.get(nodeColumn) ?? 0) + 1);
    }
    g?.connections?.forEach((c) => {
      const playerId =
        typeof c === "string" ? (c as string) : (c as PlayerAndSquad).player_id;
      const squad =
        typeof c === "string" ? undefined : (c as PlayerAndSquad).squad;

      const n1 = nodes.find((n) => n.id === g.player.id)?.position ?? {
        x: 0,
        y: 0,
      };
      const n2 = nodes.find((n) => n.id === playerId)?.position ?? {
        x: 0,
        y: 0,
      };
      if (n1.x === n2.x) {
        if (n1.y < n2.y) {
          edges.push({
            id: `${g.player.id}-${playerId}`,
            target: g.player.id,
            targetHandle: `${g.player.id}-bottom`,
            source: playerId,
            sourceHandle: `${playerId}-top`,
            type: "link",
            data: {
              squad: squad,
            },
          });
        } else {
          edges.push({
            id: `${g.player.id}-${playerId}`,
            source: g.player.id,
            sourceHandle: `${g.player.id}-top`,
            target: playerId,
            targetHandle: `${playerId}-bottom`,
            type: "link",
            data: {
              squad: squad,
            },
          });
        }
      } else if (n1.x > n2.x) {
        edges.push({
          id: `${g.player.id}-${playerId}`,
          target: g.player.id,
          targetHandle: `${g.player.id}-left`,
          source: playerId,
          sourceHandle: `${playerId}-right`,
          type: "link",
          data: {
            squad: squad,
          },
        });
      } else {
        edges.push({
          id: `${g.player.id}-${playerId}`,
          target: playerId,
          targetHandle: `${playerId}-left`,
          source: g.player.id,
          sourceHandle: `${g.player.id}-right`,
          type: "link",
          data: {
            squad: squad,
          },
        });
      }
    });
  });
  setNodes(nodes);
  setEdges(edges);
};

const dateRegex = /^([0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9])$/;

export default function Homepage() {
  const pathChunks = window.location.href.split("/");
  const lastChunk = pathChunks[pathChunks.length - 1];

  let initialDate = dateRegex.test(lastChunk)
    ? dayjs(lastChunk).set("hour", 12)
    : dayjs().set("hour", 12);

  if (initialDate.set("hour", 12).isAfter(dayjs().set("hour", 12))) {
    initialDate = dayjs().set("hour", 12);
    history.pushState(
      null,
      "Linkup",
      `${pathChunks.slice(0, pathChunks.length - 1).join("/")}`
    );
  }

  const [problemDate, setProblemDate] = useState(initialDate);

  return (
    <HomepageInner
      key={`problem-${problemDate}`}
      problemDate={problemDate}
      setProblemDate={setProblemDate}
    />
  );
}

const HomepageInner = ({
  problemDate,
  setProblemDate,
}: {
  problemDate: Dayjs;
  setProblemDate: Dispatch<SetStateAction<Dayjs>>;
}) => {
  const [win, setWin] = useState<boolean>();
  const [modal, setModal] = useState(false);
  const guessCache = localStorage.getItem(
    `linkup-guesses-${formatDate(problemDate)}`
  );
  const [guesses, setGuesses] = useState<Guess[]>(
    guessCache === null ? [] : JSON.parse(guessCache)
  );
  const [problem, setProblem] = useState<Problem>();
  const [showHelp, setShowHelp] = useState(false);
  const [portrait, setPortrait] = useState(
    window.innerHeight > window.innerWidth
  );

  useEffect(() => {
    if (localStorage.getItem("linkup-tutorial-viewed") === null) {
      setTimeout(() => setShowHelp(true), 500);
    }
  });

  useEffect(() => {
    if (guesses.find((g) => g.outcome === GuessOutcome.WIN) !== undefined) {
      setWin(true);
    } else if (guesses.length === 10) {
      setWin(false);
      setModal(true);
    }
  }, [guesses]);

  useEffect(
    () =>
      localStorage.setItem(
        `linkup-guesses-${formatDate(problemDate)}`,
        JSON.stringify(guesses)
      ),
    [guesses]
  );

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    if (problem !== undefined) {
      buildGraph(problem, guesses, setNodes, setEdges, portrait);
    }
  }, [guesses, problemDate, setEdges, setNodes, portrait]);

  const handleGuessResponse =
    (selectedPlayer: Player) => async (connectedPlayers: PlayerAndSquad[]) => {
      if (connectedPlayers.length > 0) {
        let leftConnection = false;
        let rightConnection = false;

        connectedPlayers.forEach((ps) => {
          if (leftPlayerIDs.includes(ps.player_id)) {
            leftConnection = true;
          }
          if (rightPlayerIDs.includes(ps.player_id)) {
            rightConnection = true;
          }

          if (leftConnection && rightConnection) {
            setWin(true);
            setModal(true);
          }
        });

        return getPlayer(selectedPlayer.id).then((player) => {
          if (player === null) {
            throw new Error(`player-${selectedPlayer.id} is null`);
          }
          appendState(guesses, setGuesses, {
            player,
            outcome:
              leftConnection && rightConnection
                ? GuessOutcome.WIN
                : GuessOutcome.HIT,
            connections: connectedPlayers ?? [],
            side: leftConnection ? Side.LEFT : Side.RIGHT,
          });
        });
      } else {
        return getPlayer(selectedPlayer.id).then((player) => {
          if (player !== null) {
            appendState(guesses, setGuesses, {
              player,
              outcome: GuessOutcome.MISS,
              connections: [],
              side: Side.MIDDLE,
            });
          }
        });
      }
    };

  const selectPlayer = (_: SyntheticEvent, selectedPlayer: Player | null) => {
    if (selectedPlayer !== null) {
      findPlayerConnections(selectedPlayer.id, [
        ...leftPlayerIDs,
        ...rightPlayerIDs,
      ])
        .then(handleGuessResponse(selectedPlayer))
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    const checkPortrait = () =>
      setPortrait(window.innerHeight > window.innerWidth);
    window.addEventListener("resize", checkPortrait);
    return () => window.removeEventListener("resize", checkPortrait);
  });

  useEffect(() => {
    getProblem(problemDate)
      .then((json: Problem) => {
        setProblem(json);
        leftPlayerIDs.push(json.left_player_id);
        rightPlayerIDs.push(json.right_player_id);
        buildGraph(json, guesses, setNodes, setEdges, portrait);
      })
      .catch((e) => console.error(e));
  }, [problemDate]);

  return (
    <div className="flex flex-col max-h-screen w-screen h-screen items-center gap-5 pb-5">
      <AppBar position="static" sx={{ bgcolor: "#219100" }}>
        <Toolbar>
          <h1
            className="font-black text-[#219100] grow text-xl"
            onClick={() => (window.location.href = window.location.origin)}
          >
            <span className="drop-shadow cursor-pointer bg-amber-300 py-0.5 px-1.5 rounded-lg shadow">
              LinkUp
            </span>
          </h1>
          <div className="px-2 text-white">
            <IconButton color="inherit" onClick={() => setShowHelp(true)}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="text-white border-white"
              format="YYYY-MM-DD"
              disableFuture
              minDate={dayjs("2024-07-10")}
              value={dayjs(problemDate)}
              onChange={(value: Dayjs | null) => {
                if (value !== null) {
                  value = value.set("hour", 12);
                  setProblemDate(value);

                  const pathChunks = window.location.href.split("/");
                  history.pushState(
                    null,
                    "Linkup",
                    `${pathChunks
                      .slice(0, pathChunks.length - 1)
                      .join("/")}/${value.format("YYYY-MM-DD")}`
                  );
                }
              }}
              slotProps={{
                textField: {
                  size: "small",
                  className: "w-40",
                  InputProps: {
                    className: "bg-white rounded",
                    size: "small",
                    readOnly: true,
                  },
                  inputProps: {
                    readOnly: true,
                  },
                },
                day: {
                  sx: {
                    "&.MuiPickersDay-root.Mui-selected": {
                      backgroundColor: "#219100",
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Toolbar>
      </AppBar>
      <div
        className={
          (portrait
            ? `bg-[url(/public/pitch-portrait.jpg)] `
            : `bg-[url(/public/pitch.jpg)] `) +
          "flex flex-col items-center h-full gap-5 py-3 " +
          "pb-12 bg-contain bg-center bg-no-repeat bg-[#219100] " +
          "border border-black rounded drop-shadow-xl "
        }
      >
        <SearchBar
          selectPlayer={selectPlayer}
          excludePlayers={guesses
            .filter((g) => g.outcome === GuessOutcome.HIT)
            .map((g) => g.player.id)}
          disabled={win !== undefined}
        />

        <ReactFlowProvider>
          <Graph
            nodes={nodes}
            onNodesChange={onNodesChange}
            edges={edges}
            onEdgesChange={onEdgesChange}
          />
        </ReactFlowProvider>
        <GuessDisplay guesses={guesses} />
        <EndGameModal
          open={modal}
          onClose={() => setModal(false)}
          didWin={win}
          guesses={guesses}
          problem={problem}
        />
        <HelpModal
          open={showHelp}
          onClose={() => {
            localStorage.setItem("linkup-tutorial-viewed", "true");
            setShowHelp(false);
          }}
        />
      </div>

      {win !== undefined && !modal && (
        <div
          className="bg-white rounded-t-lg w-96 z-1 flex text-center shadow-lg justify-center fixed -bottom-[1px] py-1 text-slate-500 border border-slate-300"
          onClick={() => setModal(true)}
        >
          Result <KeyboardArrowUpIcon />
        </div>
      )}
    </div>
  );
};
