import {
  Button,
  Dialog,
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
  Tooltip,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Guess, GuessDisplay, GuessOutcome } from "./GuessDisplay";
import { Problem } from "./types/problem";
import dayjs from "dayjs";
import { formatDate } from "./api/problems";
import { PlayerAndSquad } from "./types/playerAndSquad";

const SlideInFromLeft = (props: SlideProps) => {
  return <Slide {...props} direction="right" />;
};

const SlideInFromBottom = (props: SlideProps) => {
  return <Slide {...props} direction="up" />;
};

const generateSolutionClipboard = (
  problem: Problem,
  guesses: Guess[],
  didWin: boolean
) => {
  const guessCount = guesses.filter(
    (g) => g.outcome !== GuessOutcome.PENDING
  ).length;
  const shortestPathLength = didWin ? getShortestLink(guesses, problem) : -1;
  const paddedGuess = [
    ...guesses,
    ...Array<{ outcome: GuessOutcome }>(10).fill({
      outcome: GuessOutcome.PENDING,
    }),
  ].slice(0, 10);

  return (
    paddedGuess
      .map((g) => {
        switch (g.outcome) {
          case GuessOutcome.HIT:
          case GuessOutcome.WIN:
            return "🟢";
          case GuessOutcome.MISS:
            return "🔴";
          case GuessOutcome.PENDING:
            return "⚪";
        }
      })
      .join("") +
    "\n" +
    `${
      didWin
        ? `✅ I solved LinkUp in ${shortestPathLength} step${
            shortestPathLength > 1 ? "s" : ""
          }!\n`
        : `❌ I failed LinkUp after ${guessCount} tries!\n`
    }` +
    `⚽️ ${problem.left_player.name} → ${problem.right_player.name}\n` +
    "➡️ https://linkup.heg.cool"
  );
};

const getShortestLink = (guesses: Guess[], problem: Problem): number => {
  const playerMap = new Map<string, string[]>();
  guesses.forEach((g) =>
    playerMap.set(
      g.player.id,
      g.connections.map((c) =>
        typeof c === "string" ? (c as string) : (c as PlayerAndSquad).player_id
      )
    )
  );

  let leftFound = false;
  let rightFound = false;
  let count = 1;
  const tree = [guesses[guesses.length - 1].player.id];
  while (!(leftFound && rightFound)) {
    tree.forEach((id) => {
      const connections = playerMap.get(id);
      if (connections !== undefined) {
        tree.push(...connections);
      }
    });

    leftFound = leftFound || tree.includes(problem.left_player_id);
    rightFound = rightFound || tree.includes(problem.right_player_id);

    if (!leftFound) {
      count++;
    }
    if (!rightFound) {
      count++;
    }

    if (count > 10) {
      break;
    }
  }

  return count;
};

export const EndGameModal = ({
  open,
  onClose,
  didWin,
  guesses,
  problem,
}: {
  open: boolean;
  onClose: () => void;
  didWin: boolean | undefined;
  guesses: Guess[];
  problem: Problem | undefined;
}) => {
  const [showSnack, setShowSnack] = useState(false);

  const isToday =
    dayjs().set("hour", 12).format("YYYY-MM-DD") === problem?.date;
  let streak = 0;
  if (isToday) {
    let date = dayjs().set("hour", 12);
    let cont = true;
    while (cont) {
      if (streak > 10000) {
        throw new Error("looped forever whilst calculating streak");
      }
      const rawGuess = localStorage.getItem(
        `linkup-guesses-${formatDate(date)}`
      );
      if (rawGuess === null) {
        cont = false;
        continue;
      }
      const guesses: Guess[] = JSON.parse(rawGuess);
      if (guesses.find((g) => g.outcome === GuessOutcome.WIN) !== undefined) {
        streak++;
        date = date.subtract(1, "day");
      } else {
        cont = false;
      }
    }
  }

  if (problem === undefined || guesses.length === 0) {
    return null;
  }

  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setShowSnack(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <Dialog
      className="flex flex-col items-center justify-center h-full rounded-lg text-lg"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "10px" } }}
      TransitionComponent={SlideInFromBottom}
    >
      <div className="bg-white flex flex-col w-full items-center text-center px-4 pb-4 gap-4">
        {didWin ? (
          <Win guesses={guesses} problem={problem} />
        ) : (
          <Loss problem={problem} />
        )}
        <div>
          <GuessDisplay guesses={guesses} disableMiddle={true} />
        </div>
        <Button
          variant="contained"
          className="hover:shadow"
          startIcon={<ShareIcon />}
          onClick={() => {
            navigator.clipboard.writeText(
              generateSolutionClipboard(problem, guesses, !!didWin)
            );
            setShowSnack(true);
          }}
          sx={{
            backgroundColor: "#219100",
            "&:hover": {
              backgroundColor: "rgb(34 197 94)",
            },
          }}
        >
          Share your results
        </Button>
        <Tooltip
          title={
            <div className="text-base">
              <p>🟢 Connection</p>
              <p>🔴 No connection</p>
              <p>⚪ Spare guess</p>
            </div>
          }
          arrow
        >
          <div className="flex items-center text-slate-400 text-md gap-0.5 -mt-3">
            <InfoIcon fontSize="small" /> Legend
          </div>
        </Tooltip>
        {isToday && streak > 1 && (
          <p>
            🔥 You&apos;re on a <span className="font-bold">{streak} day</span>{" "}
            streak! 🔥
          </p>
        )}
        <p>Come back tomorrow for another challenge.</p>
        <Countdown />
      </div>
      <Snackbar
        open={showSnack}
        autoHideDuration={2000}
        message="📋 Copied results to clipboard"
        onClose={() => setShowSnack(false)}
        TransitionComponent={SlideInFromLeft}
        action={action}
        ContentProps={{ sx: { backgroundColor: "#219100" } }}
      />
    </Dialog>
  );
};

const Win = ({ problem, guesses }: { problem: Problem; guesses: Guess[] }) => {
  const guessCount = guesses.filter(
    (g) => g.outcome !== GuessOutcome.PENDING
  ).length;
  const shortestPathLength = useMemo(
    () => getShortestLink(guesses, problem),
    [guesses, problem]
  );

  return (
    <>
      <span className="font-black pt-4 text-xl">
        {guessCount === problem.minimum ? "🏆 TOP BINS!" : "✅ Nice one!"}
      </span>
      <div className="px-4 gap-1 flex flex-col items-center">
        <p>
          You connected{" "}
          <span className="font-bold">{problem.left_player.name}</span> &{" "}
          <span className="font-bold">{problem.right_player.name}</span> in{" "}
          <span className="font-bold">{shortestPathLength}</span>{" "}
          {shortestPathLength === 1 ? "step" : "steps."}
        </p>
        {shortestPathLength > problem.minimum && (
          <p>
            It can be done in{" "}
            <span className="font-bold">{problem.minimum}</span> steps...
          </p>
        )}
      </div>
    </>
  );
};

const Loss = ({ problem }: { problem: Problem }) => {
  return (
    <>
      <span className="font-black pt-4 text-xl">❌ Unlucky</span>
      <div className="px-4 flex flex-col items-center">
        <p>Better luck next time</p>
        <p>
          <span className="font-bold">{problem.left_player.name}</span> &{" "}
          <span className="font-bold">{problem.right_player.name}</span> can be
          connected in <span className="font-bold">{problem.minimum}</span>{" "}
          {problem.minimum === 1 ? "step" : "steps"}
        </p>{" "}
      </div>
    </>
  );
};

const secsUntilMidnight = (): number => {
  const midnight = new Date();
  midnight.setHours(24);
  midnight.setMinutes(0);
  midnight.setSeconds(0);
  midnight.setMilliseconds(0);
  return Math.floor((midnight.getTime() - new Date().getTime()) / 1000);
};

const styleCountdown = (nums: string) => {
  return nums
    .padStart(2, "0")
    .split("")
    .map((c, i) => (
      <span
        key={`sec-${i}`}
        className="bg-[#219100] p-px m-px rounded-sm text-white"
      >
        {c}
      </span>
    ));
};

const Countdown = () => {
  const [time, setTime] = useState(secsUntilMidnight());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <p className="pb-2 text-2xl font-mono font-medium">
      {styleCountdown(`${Math.floor(time / 3600)}`)}:
      {styleCountdown(`${Math.floor((time / 60) % 60)}`)}:
      {styleCountdown(`${time % 60}`)}
    </p>
  );
};
