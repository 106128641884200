import { Dayjs } from "dayjs";
import { Problem } from "../types/problem";
import { config } from "../config";

export const formatDate = (d: Dayjs) => d.format("YYYY-MM-DD");

export const getProblem = async (date: Dayjs): Promise<Problem> => {
  const cachedProblem = localStorage.getItem(
    `linkup-problem-${formatDate(date)}`
  );
  if (cachedProblem !== null && cachedProblem !== "undefined") {
    return Promise.resolve(JSON.parse(cachedProblem));
  }
  return fetch(`${config.baseUrl}/problems/${formatDate(date)}`)
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      } else {
        console.error(`${resp.status} response from problems api`);
      }
    })
    .then((problem) => {
      localStorage.setItem(
        `linkup-problem-${formatDate(date)}`,
        JSON.stringify(problem)
      );
      return problem;
    })
    .catch((err) => console.error(err));
};
